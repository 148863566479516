import { createMuiTheme } from "@material-ui/core/styles";
export var pxToRem = function (fontSize) {
    return fontSize / 16 + "rem";
};
// A custom theme for this app
var theme = createMuiTheme({
    palette: {
        grey: {
            100: "#f5f8fa",
            200: "#eaf0f6",
            300: "#dfe3eb",
            400: "#cbd6e2",
            500: "#99acc2",
            600: "#7c98b6",
            700: "#516f90",
            800: "#425b76",
            900: "#2d3e50",
        },
        common: {
            black: "#1d1d1d",
            white: "#ffffff",
        },
        background: {
            default: "#fff",
        },
        primary: {
            main: "#4169e1",
        },
        text: {
            primary: "#344563",
            secondary: "#5e6c84",
        },
    },
    spacing: 4,
    shadows: [
        "none",
        "0 2px 4px 0 rgba(48, 49, 51, 0.1)",
        "0 1px 3px 0 rgba(48, 49, 51, 0.1)",
        "0 1px 3px 0 rgba(0, 0, 0, 0.04)",
        "0 1px 2px 0 rgba(0, 0, 0, 0.08)",
        "1px 0 0 0 #f1f1f3",
        "0 1px 0 0 #eaedf3",
        "0 2px 8px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.02)",
        "0 1px 1px 0 rgba(19,31,21,0.1)",
        "0 0 11px rgb(158,158,158)",
        "0 4px 8px 0 rgba(48,49,51,0.1)",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
    ],
    typography: {
        fontFamily: "'Roboto', sans-serif",
        //add variants here
        h1: {
            fontSize: "36px",
            fontWeight: 500,
            fontStyle: "normal",
            lineHeight: "1.39",
            letterSpacing: "normal",
        },
        h2: {
            fontSize: "26px",
            fontWeight: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
        },
        h3: {
            fontSize: "23px",
            fontStyle: "normal",
            lineHeight: "1.39",
            letterSpacing: "normal",
        },
        h5: {
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "1.56",
            letterSpacing: "normal",
        },
        h6: {
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "1.43",
            letterSpacing: "normal",
        },
        body1: {
            fontSize: "14px",
            fontWeight: "bold",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
        },
        body2: {
            fontSize: "14px",
            fontWeight: "normal",
            fontStyle: "normal",
            lineHeight: "21px",
            letterSpacing: "normal",
        },
        caption: {
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            color: "#344563",
            lineHeight: "1.5",
            letterSpacing: "normal",
        },
        overline: {
            fontSize: pxToRem(10),
            fontWeight: 600,
            fontStyle: "normal",
            lineHeight: "1.5rem",
            fontStretch: "normal",
            letterSpacing: "normal",
            textTransform: "inherit",
        },
    },
    overrides: {
    //Add global css here
    },
});
export var extraColors = {
    orange: "#F6AB2F",
    lightGreen: "#d1f7c4",
    lightBlue: "#d0f0fd",
    lightCyan: "#ddf6f6",
    lightGreenBlue: "#e0f0e3",
    yellow: "#f6f4ba",
    grey: "#f4f4f4",
};
export default theme;
