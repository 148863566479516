var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import theme, { extraColors } from "../../../utils/theme";
var useStyles = makeStyles(function () {
    return createStyles({
        codeSnippet: __assign(__assign({}, theme.typography.body2), { fontWeight: "normal", fontFamily: "Roboto Mono", letterSpacing: "normal", lineHeight: 1.57 }),
        textContainer: {
            overflow: "auto",
            paddingTop: "8px",
            paddingBottom: "8px",
        },
    });
});
var CodeSnippet = function (_a) {
    var codeSnippet = _a.codeSnippet, keywords = _a.keywords, highlights = _a.highlights;
    var classes = useStyles();
    var tokens = codeSnippet.split(/(WHERE payments.state = Arizona|\s+)/);
    return (React.createElement(Grid, { container: true, direction: "row", className: classes.textContainer }, tokens.map(function (token) {
        return !token.includes("\n") ? (React.createElement(Grid, null, token.includes("WHERE payments.state = Arizona") ? (React.createElement(Box, { display: "flex" }, token.split(" ").map(function (x) {
            return (React.createElement(Typography, { className: classes.codeSnippet, style: {
                    color: new RegExp(keywords, "i").test(x)
                        ? "#8e91ff"
                        : "#344563",
                    backgroundColor: extraColors.yellow,
                    padding: theme.spacing(0, 1, 0, 1),
                } }, x));
        }))) : (React.createElement(Typography, { className: classes.codeSnippet, style: {
                color: new RegExp(keywords, "i").test(token)
                    ? "#8e91ff"
                    : "#344563",
                backgroundColor: highlights && new RegExp(highlights, "i").test(token)
                    ? extraColors.yellow
                    : "none",
                padding: highlights && new RegExp(highlights, "i").test(token)
                    ? theme.spacing(0, 1, 0, 1)
                    : theme.spacing(0),
            } }, token == " " ? React.createElement(React.Fragment, null, "\u00A0") : token)))) : (React.createElement(Grid, { container: true, style: { margin: "5px 0px" } }));
    })));
};
export default CodeSnippet;
