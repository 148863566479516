import React from "react";
import { TableContainer, TableHead, Table, TableCell, TableBody, TableRow, Typography, CircularProgress, Box, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme, { extraColors } from "../../../utils/theme";
var useStyles = makeStyles({
    table: {
        width: "531px",
        borderCollapse: "inherit",
    },
    thead: {
        background: theme.palette.grey[100],
    },
    tRow: {
        height: "37px",
    },
    headCell: {
        padding: "8px",
        fontFamily: "Inter",
        textAlign: "left",
        border: "0.2px solid " + theme.palette.grey[200],
        background: theme.palette.grey[100],
    },
    tCell: {
        padding: "2px",
        border: "0.2px solid " + theme.palette.grey[200],
        fontFamily: "Inter",
        minWidth: "85px",
        textAlign: "left",
    },
    tableBorder: {
        marginTop: theme.spacing(2),
        borderRadius: "4px",
        height: "350px",
        overflow: "overlay",
    },
    loading: {
        color: theme.palette.grey[500],
        padding: "1px",
    },
    tabsRoot: {
        minHeight: "28px",
        marginBottom: "16px",
    },
    scroller: {},
    tabRoot: {
        padding: "0px",
        minHeight: "28px",
        minWidth: "146px",
        textTransform: "none",
    },
    indicator: {
        backgroundColor: "transparent",
    },
    button: {
        backkground: theme.palette.primary.main,
    },
    "@global": {
        "*::-webkit-scrollbar": {
            width: "4px",
            height: "2px",
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.grey[500],
            borderRadius: "20px",
        },
    },
});
var VaultTable = function (_a) {
    var rows = _a.rows, columnName = _a.columnName, error = _a.error, tableTab = _a.tableTab, loading = _a.loading, highlightColumnCells = _a.highlightColumnCells;
    var classes = useStyles();
    return (React.createElement(TableContainer, { className: classes.tableBorder },
        React.createElement(Table, { stickyHeader: true, className: classes.table, "aria-label": "simple table" },
            rows && rows.length === 0 && (React.createElement("caption", { style: { textAlign: "center" } },
                React.createElement(Typography, { variant: "caption" }, "No rows"))),
            error && (React.createElement("caption", { style: { textAlign: "center" } },
                React.createElement(Typography, { variant: "caption" }, "Unable to fetch data"))),
            loading && (React.createElement("caption", { style: { textAlign: "center" } },
                React.createElement(CircularProgress, { thickness: 3, size: 20, classes: { root: classes.loading } }))),
            React.createElement(TableHead, { classes: { root: classes.thead } },
                React.createElement(TableRow, { classes: { root: classes.tRow } }, columnName.map(function (cell, index) { return (React.createElement(TableCell, { classes: { root: classes.headCell } },
                    React.createElement(Typography, { variant: "caption", style: {
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 600,
                        } }, cell))); }))),
            React.createElement(TableBody, null, rows &&
                !error &&
                rows.map(function (row, index) { return (React.createElement(TableRow, { classes: { root: classes.tRow }, style: {
                        backgroundColor: row && tableTab === 2 ? extraColors.yellow : "transparent",
                    }, key: index }, columnName.map(function (cell, index) { return (React.createElement(TableCell, { id: "tableBodyCell", classes: { root: classes.tCell }, component: "th", scope: "row", style: {
                        margin: "5px",
                        backgroundColor: tableTab === 2 && row[cell]
                            ? extraColors.yellow
                            : "transparent",
                    } },
                    React.createElement(Box, { style: {
                            padding: "3px",
                            margin: "3px",
                            backgroundColor: highlightColumnCells.includes(index)
                                ? extraColors.yellow
                                : tableTab === 2 && !row[cell]
                                    ? extraColors.grey
                                    : "inherit",
                            height: !row[cell] ? "20px" : "auto",
                        } },
                        React.createElement(Typography, { variant: "caption", style: { height: "20px" } }, row[cell])))); }))); })))));
};
export default VaultTable;
