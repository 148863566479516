import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import GovernanceDemo from "./components/pages/GovernanceDemoPage";
import theme from "../src/utils/theme";
function App() {
    return (React.createElement("div", { className: "App" },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(GovernanceDemo, null))));
}
export default App;
