var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { getBearerToken, queryRecords } from "../../../services";
import { queries } from "../../../utils/queries";
import { shuffleArray, isExpired } from "../../../utils/helper";
export var useVault = function () {
    var _a = React.useState(0), tableTab = _a[0], setTableTab = _a[1];
    var _b = React.useState(), rows = _b[0], setRows = _b[1];
    var _c = React.useState(false), loading = _c[0], setLoading = _c[1];
    var _d = React.useState(false), error = _d[0], setError = _d[1];
    var _e = React.useState(), accessToken = _e[0], setAccessToken = _e[1];
    var _f = React.useState(), tableRows = _f[0], setTableRows = _f[1];
    var _g = React.useState(), marketingRows = _g[0], setMarketingRows = _g[1];
    var _h = React.useState(), supportRows = _h[0], setSupportRows = _h[1];
    var _j = React.useState(), contextRows = _j[0], setContextRows = _j[1];
    var _k = React.useState(), caaAuthToken = _k[0], setCAAAuthToken = _k[1];
    React.useEffect(function () {
        !accessToken &&
            getBearerToken().then(function (res) {
                if (res)
                    setAccessToken(res);
                else
                    setError(true);
            });
        !caaAuthToken &&
            getBearerToken(true).then(function (res) {
                if (res)
                    setCAAAuthToken(res);
                else
                    setError(true);
            });
    }, []);
    var refreshBearerToken = function (token, isCAAAuth) { return __awaiter(void 0, void 0, void 0, function () {
        var newToken, caaAuth;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newToken = "", caaAuth = false;
                    if (isCAAAuth) {
                        caaAuth = isCAAAuth;
                    }
                    if (!isExpired(token)) return [3 /*break*/, 2];
                    return [4 /*yield*/, getBearerToken(caaAuth).then(function (res) {
                            if (res) {
                                newToken = res;
                            }
                            else
                                setError(true);
                        })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/, newToken];
            }
        });
    }); };
    var getContextAwareRows = function () { return __awaiter(void 0, void 0, void 0, function () {
        var refreshToken, contextAwareRow;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refreshBearerToken(caaAuthToken, true)];
                case 1:
                    refreshToken = _a.sent();
                    !error && setLoading(true);
                    return [4 /*yield*/, queryRecords({
                            query: queries.CONTEXT_AWARE_READ[0],
                        }, refreshToken || caaAuthToken, function (res) {
                            var _a;
                            if (res.records) {
                                setError(false);
                                contextAwareRow = (res === null || res === void 0 ? void 0 : res.records) ? ((_a = res === null || res === void 0 ? void 0 : res.records[0]) === null || _a === void 0 ? void 0 : _a.fields) || "" : "";
                            }
                            else {
                                setError(true);
                            }
                        })
                            .then(function (res) {
                            setLoading(false);
                            setContextRows([contextAwareRow]);
                        })
                            .catch(function (err) {
                            setLoading(false);
                            setError(true);
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getSupportRows = function () { return __awaiter(void 0, void 0, void 0, function () {
        var refreshToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refreshBearerToken(accessToken)];
                case 1:
                    refreshToken = _a.sent();
                    !error && setLoading(true);
                    if (!rows)
                        return [2 /*return*/];
                    return [4 /*yield*/, Promise.all(rows.map(function (row) { return __awaiter(void 0, void 0, void 0, function () {
                            var marketingRow;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, queryRecords({
                                            query: queries.CUSTOMER_SUPPORT[0] + ("'" + row.skyflow_id + "'"),
                                        }, refreshToken || accessToken, function (res) {
                                            var _a;
                                            if (res.records) {
                                                setError(false);
                                                marketingRow = (res === null || res === void 0 ? void 0 : res.records) ? ((_a = res === null || res === void 0 ? void 0 : res.records[0]) === null || _a === void 0 ? void 0 : _a.fields) || "" : "";
                                            }
                                            else {
                                                setError(true);
                                            }
                                        })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/, marketingRow];
                                }
                            });
                        }); }))
                            .then(function (res) {
                            setLoading(false);
                            setSupportRows(res);
                        })
                            .catch(function (err) {
                            setLoading(false);
                            setError(true);
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getMarketingRows = function () { return __awaiter(void 0, void 0, void 0, function () {
        var refreshToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refreshBearerToken(accessToken)];
                case 1:
                    refreshToken = _a.sent();
                    !error && setLoading(true);
                    if (!rows)
                        return [2 /*return*/];
                    return [4 /*yield*/, Promise.all(rows.map(function (row) { return __awaiter(void 0, void 0, void 0, function () {
                            var marketingRow;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, queryRecords({
                                            query: queries.MARKETING[0] + ("'" + row.skyflow_id + "'"),
                                        }, refreshToken || accessToken, function (res) {
                                            if (res.records) {
                                                setError(false);
                                                marketingRow = res.records[0].fields;
                                            }
                                            else {
                                                setError(true);
                                            }
                                        })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/, marketingRow];
                                }
                            });
                        }); }))
                            .then(function (res) {
                            setLoading(false);
                            setMarketingRows(res);
                        })
                            .catch(function (err) {
                            setLoading(false);
                            setError(true);
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getAdminRows = function () { return __awaiter(void 0, void 0, void 0, function () {
        var refreshToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refreshBearerToken(accessToken)];
                case 1:
                    refreshToken = _a.sent();
                    setError(false);
                    setLoading(true);
                    return [4 /*yield*/, Promise.all([
                            queryRecords({
                                query: queries.ADMIN[0],
                            }, refreshToken || accessToken, function (res) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2 /*return*/];
                            }); }); }),
                            queryRecords({
                                query: queries.ADMIN[1],
                            }, accessToken, function (res) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                return [2 /*return*/];
                            }); }); }),
                        ])
                            .then(function (res) {
                            setLoading(false);
                            setError(false);
                            var adminRows = res[0].records
                                .concat(res[1].records)
                                .map(function (record) {
                                return record.fields;
                            });
                            var shuffled = shuffleArray(adminRows);
                            setTableRows(shuffled);
                            setRows(shuffled);
                        })
                            .catch(function (err) {
                            setLoading(false);
                            setError(true);
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (accessToken && tableTab === 0) {
            !tableRows && getAdminRows();
        }
        else if (accessToken && tableTab === 1) {
            !marketingRows && getMarketingRows();
        }
        else if (accessToken && tableTab === 2) {
            !supportRows && getSupportRows();
        }
        else if (caaAuthToken && tableTab === 3) {
            !contextRows && getContextAwareRows();
        }
    }, [tableTab, accessToken]);
    return {
        loading: loading,
        tableRows: tableRows,
        marketingRows: marketingRows,
        supportRows: supportRows,
        contextRows: contextRows,
        error: error,
        tableTab: tableTab,
        setTableTab: setTableTab,
    };
};
