import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import theme, { extraColors, pxToRem } from "../../../utils/theme";
import PolicyHeader from "../PolicyHeader";
import ChipsList from "../ChipsList";
import TeamIcon from "../../../../public/assets/team.svg";
import CodeSnippet from "../CodeSnippet";
var useStyles = makeStyles(function () {
    return createStyles({
        root: {
            marginTop: theme.spacing(2),
            "&:first-child": {
                marginTop: 0,
            },
            position: "relative",
        },
        overview: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(1),
        },
        title: {
            fontWeight: 600,
            lineHeight: 1.5,
        },
        startIcon: {
            marginRight: theme.spacing(1),
        },
        roles: {
            backgroundColor: extraColors.lightCyan,
            width: "fitContent",
            textAlign: "center",
            paddingLeft: "4px",
            height: "20px",
        },
        categories: {
            backgroundColor: extraColors.lightGreenBlue,
        },
        divider: {
            width: "100%",
            height: pxToRem(2),
            color: theme.palette.grey[300],
            marginTop: theme.spacing(2),
        },
        iconButtonRoot: {
            padding: theme.spacing(1.5),
            margin: theme.spacing(1.5),
        },
        disabledBox: {
            opacity: 0.5,
        },
        buttons: {
            right: theme.spacing(5.5),
            "& > .MuiButton-root": {
                minHeight: "unset",
            },
        },
        editIcon: {
            marginRight: theme.spacing(1),
        },
    });
});
export var STATUS;
(function (STATUS) {
    STATUS["ACTIVE"] = "ACTIVE";
    STATUS["ARCHIVED"] = "ARCHIVED";
    STATUS["CREATED"] = "CREATED";
    STATUS["DELETED"] = "DELETED";
    STATUS["INACTIVE"] = "INACTIVE";
    STATUS["NONE"] = "NONE";
    STATUS["PENDING"] = "PENDING";
})(STATUS || (STATUS = {}));
var ACTIONS_REGEX = /(create|update|read|delete|modify|tokenization|redaction|detokenization|payments\.|all\s)/;
var HIGHLIGHT_REGEX = /(REDACTED|MASKED)/;
var HIGHLIGHT_REGEX_STRING = /(WHERE payments.state = Arizona)/;
var PolicyCard = function (_a) {
    var id = _a.id, title = _a.title, tableTab = _a.tableTab, assignedRoles = _a.assignedRoles, policyText = _a.policyText;
    var classes = useStyles();
    return (React.createElement(Box, { display: "flex", flexDirection: "column", className: classes.root },
        React.createElement(Box, { display: "flex", alignItems: "center", flexDirection: "row", width: "100%", mt: 0 },
            React.createElement(PolicyHeader, { id: id, title: title, status: null, disabled: false })),
        React.createElement(Box, { display: "flex", justifyContent: "space-between" },
            React.createElement(Box, { mt: 2.5 },
                React.createElement(ChipsList, { title: "Assigned Roles", chips: assignedRoles, icon: React.createElement("img", { src: TeamIcon, alt: "Team Icon" }), className: classes.roles }))),
        React.createElement(Box, { mt: 2, width: "464px", bgcolor: theme.palette.grey[100], pl: 4.5, pr: 4.5, display: "flex", alignItems: "center", borderRadius: pxToRem(4) },
            React.createElement(CodeSnippet, { codeSnippet: policyText
                    .map(function (rule) {
                    return rule;
                })
                    .join("\n"), keywords: ACTIONS_REGEX, highlights: tableTab == 1
                    ? HIGHLIGHT_REGEX
                    : tableTab == 2
                        ? HIGHLIGHT_REGEX_STRING
                        : undefined }))));
};
export default PolicyCard;
