var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import theme, { pxToRem } from "../../../utils/theme";
import OverviewIcon from "../../../../public/assets/overview.svg";
var useStyles = makeStyles(function () {
    return createStyles({
        overview: {
            marginRight: theme.spacing(1),
        },
        title: {
            color: "#344563",
            fontWeight: 600,
            lineHeight: 1.5,
            maxWidth: theme.spacing(125),
        },
        chip: __assign(__assign({ marginLeft: theme.spacing(2), height: pxToRem(20) }, theme.typography.overline), { color: theme.palette.primary.contrastText, backgroundColor: theme.palette.grey[800] }),
        disabledBox: {
            opacity: 0.5,
        },
    });
});
var STATUS;
(function (STATUS) {
    STATUS["ACTIVE"] = "ACTIVE";
    STATUS["ARCHIVED"] = "ARCHIVED";
    STATUS["CREATED"] = "CREATED";
    STATUS["DELETED"] = "DELETED";
    STATUS["INACTIVE"] = "INACTIVE";
    STATUS["NONE"] = "NONE";
    STATUS["PENDING"] = "PENDING";
})(STATUS || (STATUS = {}));
var PolicyHeader = function (_a) {
    var id = _a.id, title = _a.title, disabled = _a.disabled;
    var classes = useStyles();
    return (React.createElement(Box, { justifyContent: "space-between", display: "flex", flexDirection: "row", width: "100%" },
        React.createElement(Box, { display: "flex", flexDirection: "row", alignItems: "center", className: disabled ? classes.disabledBox : "", width: "100%" },
            React.createElement("img", { src: OverviewIcon, alt: "Overview Icon", className: classes.overview }),
            React.createElement(Typography, { className: classes.title, variant: "body2", noWrap: true, title: title }, title))));
};
export default PolicyHeader;
