var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Chip, Typography } from "@material-ui/core";
import theme, { pxToRem } from "../../../utils/theme";
import clsx from "clsx";
var useStyles = makeStyles(function () {
    return createStyles({
        chip: __assign(__assign({ "&:first-child": {
                marginLeft: theme.spacing(2),
            }, marginRight: theme.spacing(2), height: pxToRem(20) }, theme.typography.overline), { color: theme.palette.text.primary }),
    });
});
var ChipsList = function (_a) {
    var title = _a.title, chips = _a.chips, bgColor = _a.bgColor, className = _a.className, rest = __rest(_a, ["title", "chips", "bgColor", "className"]);
    var classes = useStyles();
    return (React.createElement(Box, { display: "flex", flexDirection: "row", alignItems: "center" },
        React.createElement(Box, null,
            React.createElement(Typography, { variant: "overline", color: "textSecondary" },
                title.toUpperCase(),
                ":")),
        React.createElement(Box, null, chips.map(function (chip, index) {
            return index <= 3 ? (React.createElement(Chip, __assign({}, rest, { label: index == 3 ? chip + " + " + (chips.length - index - 1) : chip, className: clsx(className, classes.chip), key: index, style: { textTransform: "none" } }))) : null;
        }))));
};
export default ChipsList;
