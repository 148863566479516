function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(window
        .atob(base64)
        .split("")
        .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(""));
    return JSON.parse(jsonPayload);
}
export function isExpired(token) {
    if (token === "") {
        return true;
    }
    var isJwtExpired = false;
    var decoded = parseJwt(token);
    var currentTime = new Date().getTime() / 1000;
    var expiryTime = decoded.exp;
    if (expiryTime && currentTime > expiryTime) {
        isJwtExpired = true;
    }
    return isJwtExpired;
}
export function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}
