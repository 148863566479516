import React from "react";
import { Box, Typography } from "@material-ui/core";
import logo from "../../../../public/assets/skyflow-logo.svg";
import theme from "../../../utils/theme";
import Routes from "@skyflow/docs-config";
var Background = function (props) {
    return (React.createElement(Box, { bgcolor: "#f9fafd", width: "100%", height: "100%", justifyContent: "spaceAround", minHeight: "100vh", pb: "1px" },
        React.createElement(Box, { display: "flex", width: "1160px", pt: "26px", mx: "auto", alignItems: "center" },
            React.createElement(Box, { display: "flex", width: "100%", mx: "auto", alignItems: "center" },
                React.createElement(Typography, { variant: "caption" }, "Powered by "),
                React.createElement("img", { style: { marginLeft: "2px", marginTop: "1px", width: "50px" }, src: logo })),
            React.createElement(Box, { display: "flex" },
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("a", { href: "https://docs.skyflow.com" + Routes.dataGovernanceOverview, target: "_blank", rel: "noopener noreferrer", style: {
                            textDecoration: "none",
                            color: "#4169e1",
                        } }, "Documentation")))),
        React.createElement(Box, { display: "flex", justifyContent: "center", minWidth: "1134px" },
            React.createElement(Box, { my: 6, mx: "116px", pb: 7.5, padding: "40px", borderRadius: "12px", boxShadow: theme.shadows[1], bgcolor: theme.palette.common.white },
                React.createElement(Box, { display: "flex", justifyContent: "center" },
                    React.createElement(Box, { width: "100%" },
                        React.createElement(Box, null,
                            React.createElement(Typography, { variant: "h5", color: "textPrimary", style: { fontWeight: "bold" } }, props.title),
                            React.createElement(Typography, { variant: "body2", color: "textPrimary", style: { marginTop: "12px" } }, props.subtitle),
                            React.createElement(Box, { display: "flex" }, props.body))))))));
};
export default Background;
