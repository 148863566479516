export var queries = {
    ADMIN: [
        "select skyflow_id,redaction(name,'PLAIN_TEXT'), redaction(email,'PLAIN_TEXT'), redaction(state,'PLAIN_TEXT'), redaction(ssn,'PLAIN_TEXT'), redaction(date_of_birth,'PLAIN_TEXT') from payments WHERE state!='Arizona' LIMIT 20 OFFSET 0",
        "select skyflow_id,redaction(name,'PLAIN_TEXT'), redaction(email,'PLAIN_TEXT'), redaction(state,'PLAIN_TEXT'), redaction(ssn,'PLAIN_TEXT'), redaction(date_of_birth,'PLAIN_TEXT') from payments WHERE state='Arizona' LIMIT 5 ",
    ],
    MARKETING: [
        "select redaction(name,'PLAIN_TEXT'), redaction(email,'PLAIN_TEXT'), redaction(state,'PLAIN_TEXT'),redaction( ssn,'REDACT'), redaction(date_of_birth,'MASK') from payments where skyflow_id=",
    ],
    CUSTOMER_SUPPORT: [
        "select redaction(name,'PLAIN_TEXT'), redaction(state,'PLAIN_TEXT'), ssn from payments where state = 'Arizona' AND skyflow_id=",
    ],
    CONTEXT_AWARE_READ: [
        "select * from payments;",
    ]
};
