import axios from "axios";
import properties from "../utils/properties";
var getBaseUrl = function () {
    return properties.VAULT_URL + "/v1/vaults/" + properties.VAULT_ID + "/";
};
export var getBearerToken = function (isCAAAuth) {
    return new Promise(function (resolve, reject) {
        var Http = new XMLHttpRequest();
        Http.onreadystatechange = function () {
            if (Http.readyState == 4) {
                if (Http.status == 200) {
                    var response = JSON.parse(Http.responseText);
                    resolve(response.accessToken);
                }
                else {
                    reject("Error occured");
                }
            }
        };
        Http.onerror = function (error) {
            reject("Error occured");
        };
        //token end point
        var url = properties.AWS_GATEWAY_URL + "?vaultID=" + properties.VAULT_ID;
        if (isCAAAuth) {
            url = url + "&caa=true&ctxId=cdcbe3bd-08ae-4eac-97be-3bf0dbc3e028";
        }
        Http.open("GET", url, true);
        Http.send();
    });
};
export var queryRecords = function (query, accessToken, callback) {
    return new Promise(function (resolve, reject) {
        axios
            .post(getBaseUrl() + "query", query, {
            headers: { Authorization: "Bearer " + accessToken },
        })
            .then(function (res) {
            if (callback) {
                resolve(res.data);
                callback(res.data);
            }
        })
            .catch(function (err) {
            reject(err);
            callback(err);
        });
    });
};
